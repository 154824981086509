import React from 'react';
import styled from 'styled-components';

const LogoTextStyles = styled.span`
  font-family: co-headline, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 110%;
  line-height: 1;
`;

const LogoText = () => (
  <LogoTextStyles className="logoText">marimba.nl</LogoTextStyles>
);

export default LogoText;
