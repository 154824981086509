import React from 'react';
import { Head } from 'react-static';
import styled from 'styled-components';

import Footer from '../components/Footer';
import LogoText from '../components/LogoText';
import media from '../util/media';
import GlobalStyle from '../components/GlobalStyle';

const OuterStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  h2 {
    width: 400px;
    max-width: 100%;
    color: rgba(50, 50, 50, 0.8);
  }
`;

const IntroBlockTop = styled.div`
  ${media.medium`flex: 2;`};
`;

const IntroBlockBot = styled.div`
  ${media.medium`flex: 3;`};
  //border: 3px solid yellow;
`;

const IntroBlockStyles = styled.div`
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.4)
  );
  background-attachment: fixed;
  flex: 1;
  display: flex;
  ${media.medium`
    flex-direction: row;
    background-image: linear-gradient(120deg, rgba(255,255,255,0.8), rgba(255,255,255,0.6));
    box-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  `};
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
  align-items: center;
  text-align: left;
  p {
    margin: 1em 1em 0;
  }
`;

const IntroHeadStyles = styled.div`
  padding: 0 1em;
  .headshot {
    border: 3px solid #e67e00;
    width: 140px;
    border-radius: 5px;
    ${media.medium`
      width: 140px;
      height: 140px;
    `};
  }
`;

const IntroTextStyles = styled.div`
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15),
    -1px -1px 2px rgba(255, 255, 255, 0.15);
  .motto {
    text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2),
      -1px -1px 2px rgba(255, 255, 255, 0.1);
    background-image: linear-gradient(
      120deg,
      rgba(69, 118, 178, 0.81),
      rgba(50, 70, 110, 0.81)
    );
    clip-path: polygon(12% 20%, 92% 20%, 88% 92%, 8% 92%);
    transform: rotate(-2deg);
    text-align: center;
    color: #e67e00;
    font-family: tablet-gothic-narrow, sans-serif;
    font-size: 2.5em;
    font-weight: 900;
    font-style: italic;
    margin: 0 auto;
    width: 9em;
  }
  .it {
    letter-spacing: 0.1em;
    font-size: 1.3em;
    //font-weight: 900;
    margin: 0 -0.1em 0 0.1em;
  }
`;

const IntroBlock = () => (
  <IntroBlockStyles>
    <IntroHeadStyles>
      <img
        src="/campo_weijerman_WFJ37464_600x600.jpg"
        alt="Campo Weijerman headshot"
        className="headshot"
      />
    </IntroHeadStyles>
    <IntroTextStyles>
      <p>
        Dag! Mijn naam is <em>Campo Weijerman</em> en mijn motto is
      </p>
      <div className="motto">
        <span className="mw">make</span>
        <span className="it">IT</span>
        <span className="mw">work</span>
      </div>
      <p>
        IT zou het leven makkelijker en mooier maken maar door de complexiteit
        gebeurt vaak het tegenovergestelde. Ik laat me graag uitdagen om
        oplossingen te helpen realiseren die goed werken, mooi zijn vormgegeven
        en zich in de toekomst goed laten aanpassen en onderhouden.
      </p>
    </IntroTextStyles>
  </IntroBlockStyles>
);

const HomeLogo = () => (
  <HomeLogoStyles>
    <LogoText />
  </HomeLogoStyles>
);

const HomeLogoStyles = styled.div`
  color: #f58300;
  //color: #649dfa;
  background-image: linear-gradient(
    120deg,
    rgba(69, 118, 178, 0.81),
    rgba(50, 70, 110, 0.81)
  );
  //background-color: rgba(50,70,110,0.81);
  font-size: 3em;
  //flex: 0 1 100px;

  ${media.medium`
    padding: 0.55em;
    padding-top: .7em;
  `};

  text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.2),
    -1px -1px 1px rgba(143, 76, 0, 0.6);
  .logoText {
    margin: 0 0;
    ${media.medium`
      background-image: linear-gradient(120deg, rgba(255,255,255,0.2),  rgba(255,255,255,0.1));   
      padding: 0 1em;
      border: 2px solid rgba(50,70,110,0.58);
      border-radius: 0.1em;
    `};
  }
`;

const HomeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 704px;
  & > * {
    //border: 2px dashed red;
  }
`;

export default () => (
  <OuterStyles>
    <GlobalStyle/>
    <Head>
      <title>Home | marimba.nl</title>
    </Head>
    <h1>marimba.nl | make IT work</h1>
    <HomeStyles>
      <HomeLogo />
      <IntroBlockTop />
      <IntroBlock />
      <IntroBlockBot />
      <Footer />
    </HomeStyles>
  </OuterStyles>
);
