import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import LogoText from './LogoText';

import media from '../util/media';

const FooterStyles = styled.div`
  min-height: 8em;
  font-size: 80%;
  line-height: 170%;
  background-image: linear-gradient(
    120deg,
    rgba(88, 128, 176, 0.5),
    rgba(50, 70, 110, 0.6)
  );
  color: #f2ede6;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 4px solid #e67e00;
  .grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${media.medium`
      flex-direction: row;
      flex-wrap: nowrap;
      max-width: 44rem;
    `}
    justify-content: center;
    min-height: 100%;
  }
  .footcol {
    flex: 1;
    & > * {
      flex: 1;
    }
    &.fc-2 {
      ${media.medium`order: 3`} // show at end
    }
    .head {
      font-weight: bold;
    }
  }
  p,
  a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
  }
`;

const Footer = () => (
  <FooterStyles>
    <div className="grid">
      <div className="footcol fc-1">
        <p>
          <LogoText />
        </p>
        <p>Emmalaan 113</p>
        <p>2405 GC Alphen aan den Rijn</p>
        <p>
          <a href="tel:+31646536565">06 &ndash; 46 53 65 65</a>
        </p>
      </div>
      <div className="footcol fc-2">
        <p>
          <span className="head">Btw: </span>
          <span className="item">NL135631166B01</span>
        </p>
        <p>
          <span className="head">Kvk: </span>
          <span className="item">67860494</span>
        </p>
        <p>
          <span className="head">IBAN: </span>
          <span className="item">NL44 KNAB 0257 3761 00</span>
        </p>
        <p>
          <span className="head">BIC: </span>
          <span className="item">KNABNL2H</span>
        </p>
      </div>
      <div className="footcol fc-3">
        <p>
          <Link
            to="/algemene-voorwaarden-marimba-nl.pdf"
            prefetch={false}
            target="_blank"
          >
            Algemene voorwaarden
          </Link>
        </p>
        <p>
          <Link
            to="/privacyverklaring-marimba-nl.pdf"
            prefetch={false}
            target="_blank"
          >
            Privacy verklaring
          </Link>
        </p>
        <p>
          <Link
            to="https://linkedin.com/in/nestorix"
            prefetch={false}
            target="_blank"
          >
            linkedin.com/in/nestorix
          </Link>
        </p>
        <p>
          &copy; 2019 <LogoText />
        </p>
      </div>
    </div>
  </FooterStyles>
);

export default Footer;
